import { environment } from 'src/environments/environment';

let LoginUrl = environment.LoginUrl;
let BioAuthUrl = environment.BioAuthUrl;
let OtpService = environment.OtpService;
let userRegistrationBaseUrl = environment.userRegistrationBaseUrl;
let otpServiceUrl = environment.otpServiceUrl;

export class allApi {
  public static url = {
    login: LoginUrl + '/oauth/token',
    sendOtp:
      userRegistrationBaseUrl + '/utility/send-forgot-password-otp?userName=',
    getOtp:
      userRegistrationBaseUrl + '/utility/verify-otp-send-temporary-password',
    setpass: userRegistrationBaseUrl + '/utility/send-first-login-otp',
    changePassword: userRegistrationBaseUrl + '/utility/send-first-login-otp',
    changePasswordOnFirstLoginWithOtp:
      userRegistrationBaseUrl +
      '/utility/change-password-on-first-login-with-otp',
    dashboard: userRegistrationBaseUrl + '/user/dashboard',
    registerUser:
      userRegistrationBaseUrl + '/user/register-with-verified-mobile-email',
    submit_fingerPrint: BioAuthUrl + '/KM/bioauthlogin',
    requestOtp: OtpService + '/user/send-login-otp',
    resendOtp: OtpService + '/user/send-login-otp',
    ValidateOtp: OtpService + '/user/verify-login-otp',
    sendMobileOtp: otpServiceUrl + '/sendotp',
    verifyMobileOtp: otpServiceUrl + '/verifyotp',
    sendEmailOtp: otpServiceUrl + '/sendemailotp',
    verifyEmailOtp: otpServiceUrl + '/verifyemailotp',
    dedupChk: environment.dedupChk + '/dedup/check',
  };
}
