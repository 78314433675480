<div class="container main_block">
    <div class="card outer_card dfc">
        <div class="card card_width m-0 p-0">
            <div class="card_width m-0 p-0 col-sm-12 flex_dire">
                <div class="col-sm-6">
                    <div class="row col-sm-12 m-0 p-0">
                        <div class="p-0 dfc_sml">
                            <img [src]="logoImg.logo1" alt="logo" class="logo">
                        </div>
                    </div>
                    <div class="row col-sm-12 m-0 p-0">
                        <div class="textData mt-5 dfc">
                            <p class="welcome" *ngIf="(otherSec && loginSec) || (otherSec && !loginSec)">Welcome Back
                            </p>
                            <p class="welcome" *ngIf="!otherSec && loginSec">Forgot Password?</p>
                            <p *ngIf="otherSec && loginSec">Please enter your details.</p>
                            <p *ngIf="otherSec && !loginSec">Proceed To Set Password</p>
                        </div>
                    </div>
                    <div class="row col-sm-12 m-0 p-0">
                        <div class="dfc m-0 p-0">
                            <!-- login form starts -->
                            <form [formGroup]="login_form" class="wid_80" *ngIf="otherSec && loginSec" autocomplete="off">
                                <div class="username mt-1">
                                    <label for="">Username</label>
                                    <input name="userName" autocomplete="new-userName" type="text" formControlName="userName" class="form-control w-100 mt-1">
                                </div>
                                <div class="pwd mt-1">
                                    <label for="">Password</label>
                                    <input name="password" autocomplete="new-password" type="password" formControlName="password" class="form-control w-100 mt-1"
                                        [type]="fieldTypePass?'password':'text'">
                                    <i class="fa myPos"
                                        [ngClass]="{'fa-eye-slash': fieldTypePass,'fa-eye': !fieldTypePass}"
                                        (click)="togglePass()"></i>
                                </div>
                                <div class="forgotSec mt-1">
                                    <div class="remember">
                                        <input type="checkbox" id="remember">
                                        <label for="remember" class="ml-1" style="cursor: pointer;">Remember me!</label>
                                    </div>
                                    <div class="forgot">
                                        <span class="forgotPwd" (click)="forgotPwd()">Forgot Password</span>
                                        <!-- <button class="forgotPwd" (click)="forgotPwd()">Forgot Password</button> -->
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <button class="font_mob btn w-100 login_btn" (click)="login()">
                                        <div *ngIf="sign" class="spinner-border spinner-border-sm" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <span>&nbsp; LOGIN</span>
                                    </button>
                                </div>
                            </form>
                            <!-- login form ends -->

                            <!-- forgot password section starts -->
                            <div *ngIf="!otherSec && loginSec" class="m-0 p-0 wid_80 dfc">
                                <!-- forgot password form starts -->
                                <form [formGroup]="forgotPwd_form" *ngIf="!getotp" style="width: 100%;">
                                    <div class="username mt-1">
                                        <label for="">Username</label>
                                        <input type="text" formControlName="userName" class="form-control w-100 mt-1">
                                    </div>
                                    <div class="btn_sec mt-4">
                                        <button class="font_mob btn global_btn w-50 m-1 succ_btn" (click)="sendOtp()"
                                            [disabled]="forgotPwd_form.invalid"><span
                                                class="spinner-border text-info spinner-border-sm mx-1"
                                                *ngIf="show"></span>next</button>
                                        <button class="font_mob btn global_btn w-50 m-1 can_btn"
                                            (click)="backToLogin('forgot')">back</button>

                                    </div>
                                </form>
                                <!-- forgot password form ends -->
                                <!-- get otp section starts -->
                                <div *ngIf="getotp" class="m-0 p-0 font_mob">
                                    <h2 class="text-center text-white">Forgot your password ?</h2>
                                    <div class="dfc">
                                        <ng-otp-input (onInputChange)="getOtpValue($event)"
                                            [config]="{ length: 6, allowNumbersOnly: true }" maxlength="6"></ng-otp-input>
                                    </div>
                                    <div class="mt-4 dfc p-0">
                                        <button type="button" name="button" class="font_mob btn global_btn succ_btn w-100"
                                            [disabled]="change_btn" (click)="getOtp()">
                                            <span class="spinner-border text-info spinner-border-sm mx-1"
                                                *ngIf="show"></span>Update Password
                                        </button>
                                    </div>
                                    <div class="mt-2 btn_sec p-0">
                                        <button type="button" name="button" class="font_mob btn global_btn can_btn"
                                            (click)="backToLogin('otp')">
                                            Back
                                        </button>
                                        <button type="button" name="button" class="font_mob btn global_btn other_btn"
                                            [disabled]="resend_time" (click)="sendOtp()">
                                            <span class="spinner-border text-info spinner-border-sm mx-1"
                                                *ngIf="resend"></span>Resend OTP</button>
                                        <button class="timer font_mob" *ngIf="timeleft>0">{{timeleft}} Sec</button>

                                    </div>
                                </div>
                                <!-- get otp section ends -->
                            </div>
                            <!-- forgot password section ends -->
                            <!-- set password form starts -->
                            <form [formGroup]="setPwd_form" class="wid_80" *ngIf="otherSec && !loginSec">
                                <div class="username mt-1">
                                    <label for="">Username</label>
                                    <input type="text" formControlName="userName" class="form-control w-100 mt-1"
                                        (keypress)="noSpecialChar($event)">
                                </div>
                                <div class="text-danger p-1"
                                    *ngIf="setPwd_form.get('userName').hasError('required') && setPwd_form.get('userName').touched">
                                    Its Required</div>
                                <div class="pwd mt-1">
                                    <label for="">Mobile Number</label>
                                    <input type="text" formControlName="mob" maxlength="10" minlength="10"
                                        class="form-control w-100 mt-1" (keypress)="onlyNumeric($event)">
                                    <div class="text-danger p-1"
                                        *ngIf="setPwd_form.get('mob').hasError('required') && setPwd_form.get('mob').touched">
                                        Its Required</div>
                                    <div class="text-danger p-1"
                                        *ngIf="setPwd_form.get('mob').hasError('pattern') && setPwd_form.get('mob').touched">
                                        Invalid Mobile Number</div>
                                </div>
                                <div class="btn_sec mt-4">
                                    <button class="font_mob btn global_btn w-50 m-1 succ_btn" [disabled]="setPwd_form.invalid"
                                        (click)="setpwd()">Submit</button>
                                    <button class="font_mob btn global_btn w-50 m-1 can_btn"
                                        (click)="backToLogin('set')">back</button>
                                </div>
                            </form>
                            <!-- set password form ends -->
                        </div>
                        <div class="dfc font_mob">
                            <div class="mt-2">
                                <p *ngIf="otherSec && loginSec" (click)="setPwd()"><span>New User? </span><Span
                                        class="setPwd">Set Password</Span></p>
                                <p *ngIf="(!otherSec && loginSec) && !next">Put your Login ID or Username</p>
                                <p *ngIf="otherSec && !loginSec">Enter Details to Set Password</p>
                            </div>
                            <div *ngIf="(!otherSec && loginSec) && next" class="next_block mt-3">
                                <p>Enter the OTP</p>
                                <p>Then You will Receive a New Password</p>
                                <p>in your Inbox Message</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 inner_right dfc">
                    <div class="blur_sec"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
      <p>Copyright <span style="font-size:16px">©</span> Application Owned by Kotak Mahindra Bank. All Rights Reserved. Application Maintained by iServeU Technology Pvt Ltd. For Reporting Abuse &nbsp; <a href="mailto:info@iserveu.in" > Contact Here</a></p>
  </div>
</div>


<app-ui-modal #bioAuthModal [containerClick]="false" class="custom_modal_height">
    <div class="app-modal-header dfc">
        <h3 style="font-size: 15px; color: #fff;">CAPTURE FINGERPRINT FOR BIO_AUTH</h3>
    </div>
    <div class="app-modal-body">
        <div class="card">
            <div class="toggles dfc">
                <mat-button-toggle-group #toggleGroup="matButtonToggleGroup" (change)="toggle_change($event)">
                    <mat-button-toggle value="Mantra" (click)="check_device_port('mant')"
                        [ngClass]="btn_active == 'Mantra' ? 'btn_active' : 'mat_btn_toggle'">Mantra
                    </mat-button-toggle>
                    <mat-button-toggle value="Morpho" (click)="check_device_port('morp')"
                        [ngClass]="btn_active == 'Morpho' ? 'btn_active' : 'mat_btn_toggle'">Morpho
                    </mat-button-toggle>
                    <mat-button-toggle value="Startek" (click)="check_device_port('star')"
                        [ngClass]="btn_active == 'Startek' ? 'btn_active' : 'mat_btn_toggle'">Startek
                    </mat-button-toggle>
                    <mat-button-toggle value="Precision" (click)="check_device_port('prec')"
                        [ngClass]="btn_active == 'Precision' ? 'btn_active' : 'mat_btn_toggle'">Precision
                    </mat-button-toggle>
                    <mat-button-toggle value="Tatvik" (click)="check_device_port('tatv')"
                        [ngClass]="btn_active == 'Tatvik' ? 'btn_active' : 'mat_btn_toggle'">Tatvik
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="thumb dfc">
                <mat-icon class="mat_icon dfc"
                    [ngStyle]="{'color': QScore==0 ? 'black' : QScore<40 ? 'red': QScore<60 ? '#FFCA28' :'green' }">
                    fingerprint</mat-icon>
            </div>
            <div class="thumb dfc my-2">
                <button type="button" class="btn succ_btn global_btn" (click)="captureFingerPrint()">Capture</button>
            </div>
            <div class="container my-2">
                <div class="progress">
                    <div class="progress-bar" id="progress_bar" role="progressbar" aria-valuenow="20" aria-valuemin="0"
                        aria-valuemax="100">
                    </div>
                </div>
                <h6 *ngIf="QScore"
                    [ngStyle]="{'color': QScore==0 ? 'black' : QScore<40 ? 'red': QScore<60 ? '#FFCA28' :'green' }"
                    class="my-1">
                    {{QScore}}% Captured</h6>
            </div>
            <div class="inputline dfc my-2">
                <h6 class="textclass">Recommended Fingerprint Strength is 40%</h6>
            </div>
        </div>
        <div class="thumb dfc">
            <button type="button" class="btn succ_btn mx-2 global_btn" (click)="submit_fingerprint_data()"
                [disabled]="!QScoreData">submit</button>
            <button type="button" class="btn can_btn mx-2 global_btn" (click)="cancel_bio_auth()">Cancel</button>
        </div>
    </div>
</app-ui-modal>
<!-- modal for change Password ends -->


<!-- otp modal start -->

<app-ui-modal #otpModal [containerClick]="false" class="custom_modal_height">
    <div class="app-modal-header w-100">
       <div class="d-flex justify-content-between">
        <h3 style="font-size: 15px; color: #fff;">Enter OTP to Login</h3>
       </div>
    </div>
    <div class="app-modal-body">
        <form [formGroup]="otp_form">
            <div>
                <ng-otp-input (onInputChange)="getOtpValueotp($event)" [config]="{length:6,allowNumbersOnly:true}"></ng-otp-input>

                <div class="forgetbtn" style="margin-top: 38px;" [ngClass]="resentOtp==true?'resendOtp' : ''">
                    <button class="btn btn-primary forgotbutton" (click)="ValidateUserOtp()">Verify Otp</button>
                    <button class="btn btn-primary forgetbutton" (click)="resendUserOtp()" [disabled]="resentOtp">Resend Otp</button>
                </div>
                <div class="timer"><p *ngIf="resentOtp">{{display}}s</p></div>
            </div>
        </form>
    </div>
</app-ui-modal>

<!-- otp modal end -->
