import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[imageError]',
  host: {
    '(error)': 'updateUrl()',
    '(load)': 'loadingUrl()',
  },
})
export class ImageErrorDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  updateUrl() {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'src',
      this.el.nativeElement.getAttribute('errsrc')
    );
  }

  loadingUrl() {}
}
