import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = sessionStorage.getItem('access_token')
      ? sessionStorage.getItem('access_token')
      : '';

    if (req.url.includes('oauth/token')) {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ` + btoa('isu-kotak-client:isu-kotak-password'),
        },
      });
    } else if (req.url.endsWith('KM/login/bio_auth')) {
      req = req.clone({
        setHeaders: {
          Authorization: token,
        },
      });
    } else if (
      req.url.includes('/utility/send-forgot-password-otp?userName=') ||
      req.url.includes('/utility/verify-otp-send-temporary-password') ||
      req.url.includes('/utility/send-first-login-otp')
    ) {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        },
      });
    } else if (
      req.url.endsWith('user/register-with-verified-mobile-email') ||
      req.url.endsWith('dedup/check') ||
      req.url.includes('https://otp-service-twdwtabx5q-uc.a.run.app')
    ) {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        },
      });
    } else {
      req = req.clone({
        setHeaders: {
          Authorization: token,
        },
      });
    }
    return next.handle(req);
  }
}
