import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DataFilterPipe } from './components/data-table/data-filter.pipe';

import { SpinnerComponent } from './components/spinner/spinner.component';

import { ApexChartService } from './components/chart/apex-chart/apex-chart.service';
import { ValidMobileDirective } from './directives/valid-mobile-number.directive';
import { ValidAmountDirective } from './directives/valid-amount.directive';
import {
  NgbPopoverModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
  NgbButtonsModule,
  NgbDropdownModule,
  NgbTabsetModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ValidBeneNameDirective } from './directives/valid-bene-name.directive';
import { ValidAccountNumberDirective } from './directives/valid-acc-number.directive';
import { ValidIfscDirective } from './directives/valid-ifsc.directive';
import { ValidOTPFieldDirective } from './directives/valid-otp-field.directive';
import { CamelToCapitalPipe } from './pipes/camelToCapitalize.pipe';
import { ImageErrorDirective } from './directives/image-error.directive';
import { ValidAmountWithDecimalDirective } from './directives/valid-amount-with-decimal.directive';
import { ValidpasswordDirective } from './directives/validpassword.directive';
import { ValidotpDirective } from './directives/validotp.directive';
import { ModalModule } from './components';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbTypeaheadModule,
    NgbButtonsModule,
    NgbDropdownModule,
    NgbTabsetModule,
    NgbModule,
    MatInputModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressBarModule,
  ],
  exports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    ModalModule,
    DataFilterPipe,
    SpinnerComponent,

    ValidMobileDirective,
    ValidAmountDirective,
    NgbTooltipModule,
    ValidBeneNameDirective,
    NgbPopoverModule,
    ValidAccountNumberDirective,
    ValidIfscDirective,
    ValidOTPFieldDirective,
    CamelToCapitalPipe,
    ImageErrorDirective,
    NgbTypeaheadModule,
    ValidAmountWithDecimalDirective,
    NgbButtonsModule,
    NgbDropdownModule,
    NgbTabsetModule,

    NgbModule,
    MatInputModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressBarModule,
  ],
  declarations: [
    DataFilterPipe,
    SpinnerComponent,

    ValidIfscDirective,
    ValidMobileDirective,
    ValidAmountDirective,
    ValidBeneNameDirective,
    ValidOTPFieldDirective,
    ValidAccountNumberDirective,
    CamelToCapitalPipe,
    ImageErrorDirective,
    ValidAmountWithDecimalDirective,
    ValidpasswordDirective,
    ValidotpDirective,
  ],
  providers: [ApexChartService],
})
export class SharedModule {}
