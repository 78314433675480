<div id="http-loader" *ngIf="isSpinnerVisible">
    <div class="loader-bg">
        <!-- material-line -->
        <!-- <div class="sk-line-material" [class.colored]="!backgroundColor" *ngIf="spinner === Spinkit.skLine">
            <div class="sk-child sk-bounce1" [style.background-color]='backgroundColor'></div>
        </div> -->


        <div class="loader-spinner-container" *ngIf="spinner === Spinkit.skLine">

            <div class="test-spinner" [class.colored]="!backgroundColor">
                <div class="bounce1" [style.background-color]='backgroundColor'></div>
                <div class="bounce2" [style.background-color]='backgroundColor'></div>
                <div class="bounce3" [style.background-color]='backgroundColor'></div>
            </div>
            
        </div>
    </div>
</div>
