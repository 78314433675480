import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { assetUrl } from 'src/single-spa/asset-url';
import { AppService } from '../app.service';
import { allApi } from '../appApi';
import jwtDecode from 'jwt-decode';
import { Router } from '@angular/router';
import * as Notiflix from 'notiflix';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {



  myxmldata: any;
  fingerData: any = {
    mobileNumber: "",
    aadharNo: "",
    iin: "",
    amount: "",
    bankName: "",
    ipAddress: "",
    latLong: "",
    dpId: "",
    rdsId: "",
    rdsVer: "",
    dc: "",
    mi: "",
    mcData: "",
    sKey: "",
    hMac: "",
    pidData: "",
    encryptedPID: "",
    ci: "",
    apiUser: "",
    freshnessFactor: "",
    shakey: "",
    gatewayPriority: "",
    device_sl_no: ""
  };


  otp_form: FormGroup;
  login_form: FormGroup;
  forgotPwd_form: FormGroup;
  setPwd_form: FormGroup;
  otherSec: boolean = true;
  loginSec: boolean = true;
  getotp: boolean = false;
  next: boolean = false;
  show: boolean = false;
  resend: boolean = false;
  resend_time: boolean = true;
  logoIcon1: any = assetUrl('images/kotak_logo1.png');
  logoImg: any = {
    logo1: assetUrl('images/saarthi_logo.png'),
    logo2: assetUrl('images/kotak_logo1.png')
  }




  input_otp: any;
  modal_input_otp: any;
  change_btn: boolean = true;
  username: any;
  timeleft: any = 60;
  fieldTypePass: boolean = true;
  sign: boolean = false;
  bioAuth: boolean = false;
  isPwdRqrd: boolean = false;
  QScore: any = 0;
  QScoreData: boolean = false;
  btn_active: any = 'Mantra'

  @ViewChild('bioAuthModal', { static: false }) private bioAuth_Modal: any;
  @ViewChild('otpModal', { static: false }) private otpModal: any;
  tabchangevalue: any = 'Mantra';
  dev_name: any = 'Mantra';
  dummyFlag: boolean = false;
  deviceConnected_checked: boolean = false;
  isConnected: any = 'Device is not Connected';
  check_device_status: string;
  selected_port: any = '00';
  device_installed: any;
  check_device_data: any;
  check_device_info: any;
  deviceSerialNo: any;
  errorData: any;
  role: any;
  userRole: any;

  paramsKey: string;
  timer: any = 10;
  otptimer: any;
  timerInterval: any;
  display: any;
  resentOtp: boolean = false;
  is2fa: boolean;
  brand_name: any;
  isPasswordResetRequired: any;

  constructor(
    private appService: AppService, private router: Router, private http: HttpClient
  ) {
    this.login_form = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
    this.setPwd_form = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      mob: new FormControl('', [Validators.required, Validators.pattern(/^[6-9][0-9]{9}$/)])
    });
    this.forgotPwd_form = new FormGroup({
      userName: new FormControl('', [Validators.required])
    });
    this.otp_form = new FormGroup({
      otp: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {

  }
  login = () => {
    if (this.login_form.invalid) {
      Notiflix.Notify.warning('Kindly Enter the Credentials');
    } else {
      let dis = this;
      dis.sign = true;
      Notiflix.Loading.arrows('Loading...');
      let params = new URLSearchParams();
      params.set('grant_type', 'password');
      params.set('username', this.login_form.get('userName')?.value);
      params.set('password', this.login_form.get('password')?.value);
      this.appService.post_method(allApi.url.login, params.toString())
        .pipe()
        .subscribe({
          next(res: any) {

            if (res) {
              var token: any = jwtDecode(res.access_token);
              var logouturl = window.location.host;
              var domain = window.location.host;
              var access_token = res.access_token;
              var refresh_token = res.refresh_token;
              var exp = res.expires_in;
              var adminname = res.adminName;
              sessionStorage.setItem('refresh_token', refresh_token);
              sessionStorage.setItem('access_token', access_token);
              sessionStorage.setItem('exp', exp);
              localStorage.setItem('admin_name', adminname);
              sessionStorage.setItem('loginInfo', JSON.stringify(res));
              sessionStorage.setItem('CORE_SESSION', res.access_token);
              localStorage.setItem('logouturl', logouturl);
              sessionStorage.setItem('isPasswordResetRequired', res.isPasswordResetRequired);
              dis.role = token.authorities[0];
              sessionStorage.setItem('userType', dis.role);
              dis.bioAuth = res.isBioAuthRequired ? true : false;
              dis.is2fa = res.is2FAEnabled ? true : false;
              dis.isPwdRqrd = res.isPasswordResetRequired ? true : false;

              if (dis.role == 'ROLE_MASTER_DISTRIBUTOR') {
                dis.userRole = 'distributor';
                dis.dashBoard();

              } else {
                Notiflix.Loading.remove();
                Notiflix.Report.warning('Opps!!!', 'You Are Not Authorised to Login', 'Close')
              }
            }
          },
          error(err: any) {

            Notiflix.Loading.remove();
            if (err.status == 0) {
              dis.sign = false;
              Notiflix.Notify.failure('Server Error');
            } else {
              dis.sign = false;
              Notiflix.Notify.failure(err.error.error_description);
            }
          }
        })
    }
  }
  submit_fingerprint_data = () => {
    let dis = this;
    Notiflix.Loading.dots();
    let reqBody = {
      device_sl_no: this.deviceSerialNo,
      dpId: this.fingerData.dpId,
      rdsId: this.fingerData.rdsId,
      rdsVer: this.fingerData.rdsVer,
      dc: this.fingerData.dc,
      mi: this.fingerData.mi,
      mcData: this.fingerData.mcData,
      sKey: this.fingerData.sKey,
      hMac: this.fingerData.hMac,
      encryptedPID: this.fingerData.encryptedPID,
      ci: this.fingerData.ci,
      user_name: this.username
    };
    this.http.post(allApi.url.submit_fingerPrint, reqBody).subscribe({
      next: (res: any) => {

        Notiflix.Loading.remove();
        Notiflix.Notify.success(res.message);
        dis.bioAuth_Modal.hide();
        switch (dis.role) {
          case 'ROLE_ADMIN':
            dis.userRole = 'admin_so';
            dis.router.navigate(['/admin/dashboard']);
            break;
          case 'ROLE_SALES_OFFICE':
            dis.userRole = 'admin';
            dis.router.navigate(['/admin/dashboard']);
            break;
          case 'ROLE_HEAD_OFFICE':
            dis.userRole = 'admin_ho';
            dis.router.navigate(['/admin/dashboard']);
            break;
          case 'ROLE_RETAILER':
            dis.userRole = 'retailer';
            dis.router.navigate(['retailer/dashboard']);
            break;
          case 'ROLE_DISTRIBUTOR':
            dis.userRole = 'distributor';
            dis.router.navigate(['distributor/dashboard']);
            break;
          default:
            dis.userRole = 'distributor';
            dis.router.navigate(['distributor/dashboard']);
            break;
        }

      },
      error: (err: any) => {

        Notiflix.Loading.remove();

      }
    })
  }
  onlyNumeric(event: any) {
    return event.charCode >= 48 && event.charCode <= 57;
  }
  noSpecialChar(event: any) {
    return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode >= 48 && event.charCode <= 57;
  }
  backToLogin = (e: any) => {
    this.timeleft = 60;
    if (e == 'otp') {
      this.getotp = false;
    } else {
      this.loginSec = true;
      this.otherSec = true;
      this.forgotPwd_form.reset();
      this.setPwd_form.reset();
    }

  }
  forgotPwd = () => {
    this.login_form.reset();
    this.loginSec = true;
    this.otherSec = false;
  }
  setPwd = () => {
    this.login_form.reset();
    this.loginSec = false;
    this.otherSec = true;
  }
  toggle_change = (e: any) => {
    this.btn_active = e.value;
    this.tabchangevalue = e.value;
    this.dev_name = e.value;
    this.dummyFlag = false;
    this.deviceConnected_checked = false;
    this.isConnected = " Device is not Connected";
    this.QScore = 0;
    let px = 443;
    let progress = (px * this.QScore) / 100;
    let progVal = progress + 'px';
    document.getElementById("progress_bar").style.width = progVal;
    this.QScoreData = false;
  }
  check_device_port = (e: any) => {

    this.check_device_status = '';
    Notiflix.Loading.hourglass('Loading...');
    const recursiveCheckDevice = (index) => {
      setTimeout(() => {
        let indexNum = index < 10 ? `0${Math.abs(index)}` : index;
        let convStr: any;
        var s = new XMLSerializer();
        this.selected_port = indexNum ? indexNum : this.selected_port;
        $.ajax({
          type: "RDSERVICE",
          async: false,
          crossDomain: true,
          url: `http://127.0.0.1:111${this.selected_port}/`,
          contentType: "text/xml; charset=utf-8",
          processData: false,
          success: (data) => {
            if (typeof (data) == "object") {
              convStr = s.serializeToString(data);
            } else {
              convStr = data;
            };
            if (convStr.includes('status="READY"') && convStr.includes(this.tabchangevalue)) {
              this.check_device_status = "READY";
              index = 22;
            }
            else if (convStr.includes('status="READY"') && !(convStr.includes(this.tabchangevalue))) {
              this.check_device_status = this.tabchangevalue;
              index = 22;
            }
            else if (convStr.includes('status="NOTREADY"')) {

              index++;
              recursiveCheckDevice(index);
            }
          },
          error: function () {
            this.check_device_status = "ERROR";
            this.deviceCheckLoader = "data";
            index++;
            if (index < 21) {
              recursiveCheckDevice(index);
            }
          },
        });

        if (this.check_device_status === "READY") {
          Notiflix.Loading.remove();
          this.check_device(e);
        }
        else if (this.check_device_status === this.tabchangevalue) {
          Notiflix.Loading.remove();


          Notiflix.Report.warning('Kindly Choose Connected Device', '', 'ok');
        }
        else if (this.check_device_status === "NOTREADY") {
          Notiflix.Loading.remove();


          Notiflix.Report.failure('Device is not connected', '', 'ok');
        } else if (index === 21) {
          Notiflix.Loading.remove();


          Notiflix.Report.failure('Device is not connected', '', 'ok');
        }
      }, 300)
    }
    let index = 0;
    recursiveCheckDevice(index);
  }
  check_device(e) {
    Notiflix.Loading.hourglass('Loading...');
    this.check_info();
    var dis = this;
    var res;
    var httpStaus = false;
    var deviceConnected_checked = false;
    var deviceFlag;
    var convStr;
    var s = new XMLSerializer();

    if (e == 'tatv') {
      $.ajax({
        type: "RDSERVICE",
        async: false,
        crossDomain: true,
        url: `http://127.0.0.1:111${this.selected_port}/`,
        contentType: "text/xml; charset=utf-8",
        processData: false,
        success: (data) => {
          Notiflix.Loading.remove();
          this.check_device_data = data;
          let qscore = data;
          httpStaus = true;
          res = {
            data: data,
            httpStaus: httpStaus,
          };

          if (data.includes('status="READY"')) {
            Notiflix.Report.success('Device is connected', '', 'ok');
            dis.device_installed = 'tatvik';
          } else if (data.includes('status="NOTREADY"')) {
            Notiflix.Report.failure('Device is not connected...', '', 'ok');
          } else {
            Notiflix.Report.info('Kindly choose installed device...', '', 'ok');
          }
        },
        error: function () {
          Notiflix.Report.failure('Error in RD Service. Kindly Connect with support team...', '', 'ok');
          Notiflix.Loading.remove();
          res = {
            httpStaus: httpStaus,
          };
        },
      });
      this.dummyFlag = deviceFlag;
      this.deviceConnected_checked = deviceConnected_checked;
      this.isConnected = " Device is Connected";
      return res
    }

    else if (e == 'mant') {
      $.ajax({
        type: "RDSERVICE",
        async: false,
        crossDomain: true,
        url: `http://127.0.0.1:111${this.selected_port}/`,
        contentType: "text/xml; charset=utf-8",
        processData: false,
        success: (data) => {
          Notiflix.Loading.remove();
          let qscore = data;
          httpStaus = true;
          res = {
            data: data,
            httpStaus: httpStaus,
          };

          if (typeof (data) == "object") {
            convStr = s.serializeToString(data);
          } else {
            convStr = data;
          };

          if (convStr.includes('status="READY"') && convStr.includes('Mantra')) {
            deviceFlag = true;
            deviceConnected_checked = true;
            dis.device_installed = 'mantra';
            Notiflix.Report.success('Device is connected', '', 'ok');
          } else if (convStr.includes('status="NOTREADY"') && convStr.includes('Mantra')) {
            Notiflix.Report.failure('Device is not connected...', '', 'ok');
          }
          else if (convStr.includes('Morpho_RD_Service')) {
            Notiflix.Report.info('Kindly Choose Morpho Device...', '', 'ok');
          }
          else if (convStr.includes('FM220')) {
            Notiflix.Report.info('Kindly Choose Startek Device...', '', 'ok');
          }
          else if (convStr.includes('Precision')) {
            Notiflix.Report.info('Kindly Choose Precision Device...', '', 'ok');
          } else {
            Notiflix.Report.info('Kindly choose installed device...', '', 'ok');
          }
        },
        error: function (jqXHR, ajaxOptions, thrownError) {
          Notiflix.Loading.remove();
          Notiflix.Report.failure(`${thrownError} If you are using tatvik device then select the Device from Drop down`, '', 'ok');
          res = {
            httpStaus: httpStaus,
          };
        },
      });
      this.dummyFlag = deviceFlag;
      this.deviceConnected_checked = deviceConnected_checked;
      this.isConnected = " Device is Connected";
      return res
    }

    else if (e == 'morp') {
      $.ajax({
        type: "RDSERVICE",
        async: false,
        crossDomain: true,
        url: `http://127.0.0.1:111${this.selected_port}/`,
        contentType: "text/xml; charset=utf-8",
        processData: false,
        success: (data) => {
          Notiflix.Loading.remove();
          let qscore = data;
          httpStaus = true;
          res = {
            data: data,
            httpStaus: httpStaus,
          };
          if (typeof (data) == "object") {
            convStr = s.serializeToString(data);
          } else {
            convStr = data;
          }
          if (convStr.includes('status="READY"') && convStr.includes('Morpho_RD_Service')) {
            deviceFlag = true;
            deviceConnected_checked = true;
            dis.device_installed = 'morpho';
            Notiflix.Report.success('Device is connected...', '', 'ok');
          } else if (convStr.includes('status="NOTREADY"') && convStr.includes('Morpho_RD_Service')) {
            Notiflix.Report.info('Device is not connected...', '', 'ok');
          }
          else if (convStr.includes('Mantra')) {
            Notiflix.Report.info('Kindly Choose Mantra Device...', '', 'ok');
          }
          else if (convStr.includes('FM220')) {
            Notiflix.Report.info('Kindly Choose Startek Device...', '', 'ok');
          }
          else if (convStr.includes('Precision')) {
            Notiflix.Report.info('Kindly Choose Precision Device...', '', 'ok');
          } else {
            Notiflix.Report.info('Kindly Choose installed Device...', '', 'ok');
          };;
        },
        error: function (jqXHR, ajaxOptions, thrownError) {
          Notiflix.Loading.remove();
          Notiflix.Report.failure(`${thrownError} If you are using tatvik device then select the Device from Drop down`, '', 'ok');
          res = {
            httpStaus: httpStaus,
          };
        },
      });
      this.dummyFlag = deviceFlag;
      this.deviceConnected_checked = deviceConnected_checked;
      this.isConnected = " Device is Connected";
      return res
    }

    else if (e == 'star') {
      $.ajax({
        type: "RDSERVICE",
        async: false,
        crossDomain: true,
        url: `http://127.0.0.1:111${this.selected_port}/`,
        contentType: "text/xml; charset=utf-8",
        processData: false,
        success: (data) => {
          Notiflix.Loading.remove();
          let qscore = data;
          httpStaus = true;
          res = {
            data: data,
            httpStaus: httpStaus,
          };
          if (typeof (data) == "object") {
            convStr = s.serializeToString(data);
          } else {
            convStr = data;
          }
          if (convStr.includes('status="READY"') && convStr.includes('FM220')) {
            deviceFlag = true;
            deviceConnected_checked = true;
            dis.device_installed = 'startek';
            Notiflix.Report.success('Device is connected...', '', 'ok');
          } else if (convStr.includes('status="NOTREADY"') && convStr.includes('FM220')) {
            Notiflix.Report.info('Device is not connected...', '', 'ok');
          }
          else if (convStr.includes('Mantra')) {
            Notiflix.Report.info('Kindly Choose Mantra Device...', '', 'ok');
          }
          else if (convStr.includes('Morpho_RD_Service')) {
            Notiflix.Report.info('Kindly Choose Morpho Device...', '', 'ok');
          }
          else if (convStr.includes('Precision')) {
            Notiflix.Report.info('Kindly Choose Precision Device...', '', 'ok');
          }
          else {
            Notiflix.Report.info('Kindly Choose installed Device...', '', 'ok');
          };
        },
        error: function (jqXHR, ajaxOptions, thrownError) {
          Notiflix.Loading.remove();
          Notiflix.Report.failure(`${thrownError} If you are using tatvik device then select the Device from Drop down`, '', 'ok');
          res = {
            httpStaus: httpStaus,
          };
        },
      });
      this.dummyFlag = deviceFlag;
      this.deviceConnected_checked = deviceConnected_checked;
      this.isConnected = " Device is Connected";
      return res
    }

    else if (e == 'prec') {
      $.ajax({
        type: "RDSERVICE",
        async: false,
        crossDomain: true,
        url: `http://127.0.0.1:111${this.selected_port}/`,
        contentType: "text/xml; charset=utf-8",
        processData: false,
        success: (data) => {
          Notiflix.Loading.remove();
          let qscore = data;
          httpStaus = true;
          res = {
            data: data,
            httpStaus: httpStaus,
          };
          if (typeof (data) == "object") {
            convStr = s.serializeToString(data);
          } else {
            convStr = data;
          }
          if (convStr.includes('status="READY"') && convStr.includes('Precision')) {
            deviceFlag = true;
            deviceConnected_checked = true;
            dis.device_installed = 'precision';
            Notiflix.Report.success('Device is connected...', '', 'ok');
          } else if (convStr.includes('status="NOTREADY"') && convStr.includes('Precision')) {
            Notiflix.Report.info('Device is not connected...', '', 'ok');
          }
          else if (convStr.includes('Mantra')) {
            Notiflix.Report.info('Kindly Choose Mantra Device...', '', 'ok');
          }
          else if (convStr.includes('Morpho_RD_Service')) {
            Notiflix.Report.info('Kindly Choose Morpho Device...', '', 'ok');
          }
          else if (convStr.includes('FM220')) {
            Notiflix.Report.info('Kindly Choose Startek Device...', '', 'ok');
          }
          else {
            Notiflix.Report.info('Kindly Choose installed Device...', '', 'ok');
          }
        },
        error: function (jqXHR, ajaxOptions, thrownError) {
          Notiflix.Loading.remove();
          Notiflix.Report.failure(`${thrownError} If you are using tatvik device then select the Device from Drop down`, '', 'ok');
          res = {
            httpStaus: httpStaus,
          };
        },
      });
      this.dummyFlag = deviceFlag;
      this.deviceConnected_checked = deviceConnected_checked;
      this.isConnected = " Device is Connected";
      return res
    }
  }
  check_info() {
    $.ajax({
      type: "DEVICEINFO",
      async: false,
      crossDomain: true,
      url: `http://127.0.0.1:111${this.selected_port}/`,
      contentType: "text/xml; charset=utf-8",
      processData: false,
      success: (data) => {
        Notiflix.Loading.remove();
        this.check_device_info = data;

        this.deviceSerialNo = $(this.check_device_info).find('additional_info').find('Param').attr('value');
      },
      error: function () {
        Notiflix.Report.failure('Error in RD Service fetching info. Kindly Connect with support team...', '', 'ok');
      },
    });
  }
  togglePass() {
    this.fieldTypePass = !this.fieldTypePass;
  }

  captureFingerPrint() {
    if (!this.deviceConnected_checked) {
      Notiflix.Report.failure('Device is not connected', '', 'ok');
    } else {
      let myres = this.regCustomer4();
      if (myres.httpStaus) {
        let dataXML = myres.data;
        if (typeof dataXML === 'string') {
          this.myxmldata = btoa(dataXML);
        }
        else if (typeof dataXML === 'object') {
          this.myxmldata = dataXML;
          let mydata2 = new XMLSerializer().serializeToString(this.myxmldata);
          this.myxmldata = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?> ${mydata2}`;
          this.myxmldata = btoa(this.myxmldata);
        }
        this.QScore = $(dataXML).find('Resp').attr('qScore');
        let px = 443;
        let progress = (px * this.QScore) / 100;
        let progVal = progress + 'px';
        document.getElementById("progress_bar").style.width = progVal;


        this.errorData = $(dataXML).find('Resp').attr('errCode');
        if (this.QScore >= 40 || $(dataXML).find('DeviceInfo').attr('dpId') == 'Morpho.SmartChip') {
          Notiflix.Report.success('Scan Successful', '', 'ok');
          this.fingerData.dpId = $(dataXML).find('DeviceInfo').attr('dpId');
          this.fingerData.rdsId = $(dataXML).find('DeviceInfo').attr('rdsId');
          this.fingerData.rdsVer = $(dataXML).find('DeviceInfo').attr('rdsVer');
          this.fingerData.dc = $(dataXML).find('DeviceInfo').attr('dc');
          this.fingerData.mi = $(dataXML).find('DeviceInfo').attr('mi');
          this.fingerData.mcData = $(dataXML).find('DeviceInfo').attr('mc');
          this.fingerData.sKey = $(dataXML).find('Skey').text();
          this.fingerData.hMac = $(dataXML).find('Hmac').text();
          this.fingerData.encryptedPID = $(dataXML).find('Data').text();
          this.fingerData.ci = $(dataXML).find('Skey').attr('ci');
          this.QScoreData = true;
        }
        else if (this.QScore >= 40 || $(dataXML).find('DeviceInfo').attr('dpId') == 'TATVIK.TATVIK') {
          Notiflix.Report.success('Scan Successful', '', 'ok');
          this.QScore = parseInt(this.QScore) + 50;
          this.fingerData.dpId = $(dataXML).find('DeviceInfo').attr('dpId');
          this.fingerData.rdsId = $(dataXML).find('DeviceInfo').attr('rdsId');
          this.fingerData.rdsVer = $(dataXML).find('DeviceInfo').attr('rdsVer');
          this.fingerData.dc = $(dataXML).find('DeviceInfo').attr('dc');
          this.fingerData.mi = $(dataXML).find('DeviceInfo').attr('mi');
          this.fingerData.mcData = $(dataXML).find('DeviceInfo').attr('mc');
          this.fingerData.sKey = $(dataXML).find('Skey').text();
          this.fingerData.hMac = $(dataXML).find('Hmac').text();
          this.fingerData.encryptedPID = $(dataXML).find('Data').text();
          this.fingerData.ci = $(dataXML).find('Skey').attr('ci');
          this.QScoreData = true;
        }
        else if (this.errorData != 0) {
          Notiflix.Report.failure($(dataXML).find('Resp').attr('errInfo'), '', 'ok');
          this.QScore = 0;
        } else {
          Notiflix.Report.info('Bad Quality, Please Try Again', '', 'ok');
          this.QScoreData = false;
          this.QScore = 0;
        }
      }
    }

    let reqBody = {
      device_sl_no: this.deviceSerialNo,
      dpId: this.fingerData.dpId,
      rdsId: this.fingerData.rdsId,
      rdsVer: this.fingerData.rdsVer,
      dc: this.fingerData.dc,
      mi: this.fingerData.mi,
      mcData: this.fingerData.mcData,
      sKey: this.fingerData.sKey,
      hMac: this.fingerData.hMac,
      encryptedPID: this.fingerData.encryptedPID,
      ci: this.fingerData.ci,
      user_name: this.username
    };


  }
  regCustomer4() {

    var res: any;
    var httpStaus = false;
    $.ajax({
      type: "CAPTURE",
      async: false,
      crossDomain: true,
      url: `http://127.0.0.1:111${this.selected_port}/`,
      data: '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="30000" posh="" env="P" /> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>',
      contentType: "text/xml; charset=utf-8",
      processData: false,
      success: function (data) {

        let qscore = data;

        httpStaus = true;
        res = {
          data: data,
          httpStaus: httpStaus,
        };
      },
      error: function (jqXHR, ajaxOptions, thrownError) {


        Notiflix.Report.failure(`${thrownError} If you are using tatvik device then select the Device from Drop down`, '', 'ok');
        res = {
          httpStaus: httpStaus,
        };
      },
    });
    return res
  }
  check_bioauth = () => {
    this.bioAuth_Modal.hide();
  }
  cancel_bio_auth = () => {
    this.bioAuth_Modal.hide();
    this.login_form.reset();
  }
  getOtpValue = (e: any) => {
    this.input_otp = e;
    if (e.length == 6) {
      this.change_btn = false;
    } else {
      this.change_btn = true;
    }
  }
  resend_timer = () => {
    let dis = this;
    let downloadTimer = setInterval(function () {
      if (dis.timeleft < 2) {
        clearInterval(downloadTimer);
        dis.resend_time = false;
      } else {
        dis.resend_time = true;
      }
      dis.timeleft -= 1;
    }, 1000);

  }
  sendOtp = () => {
    let dis = this;
    this.show = true;
    let reqBody = {};
    this.username = this.forgotPwd_form.get('userName')?.value

    let url = allApi.url.sendOtp + `${this.username}`;
    this.appService.post_method(url, reqBody)
      .pipe()
      .subscribe({
        next(res: any) {

          if (res.statusCode == -1) {
            Notiflix.Notify.failure(res.statusDesc);
            dis.show = false;
            dis.forgotPwd_form.reset();
          } else {
            dis.getotp = true;
            dis.next = true;
            dis.show = false;
            dis.timeleft = 60;
            dis.resend_timer();
          }
        },
        error(err: any) {

          dis.show = false;
          Notiflix.Notify.failure(err.error.statusDesc);
        }
      });
  }
  getOtp = () => {
    let dis = this;
    this.show = true;
    let reqBody = {
      userName: this.username,
      otp: this.input_otp
    };
    let url = allApi.url.getOtp;
    this.appService.post_method(url, reqBody)
      .pipe()
      .subscribe({
        next(res: any) {
          if (res.statusCode == -1) {
            Notiflix.Notify.failure(res.statusDesc);
            dis.show = false;
          }
          else {
            dis.show = false;
            Notiflix.Notify.success(res.statusDesc);
            dis.otherSec = true;
            dis.loginSec = true;
          }
        },
        error(err: any) {

          dis.show = false;
          if (err.error.statusCode == -1) {
            Notiflix.Notify.failure(err.error.statusDesc);
            dis.getotp = true;
          } else if(err.error.statusCode == -2) {
            Notiflix.Notify.failure(err.error.statusDesc);
            dis.getotp = false;
            dis.loginSec = true;
            dis.otherSec = true;
            setTimeout(() => {
              dis.router.navigateByUrl("/login");
            }, 2000);
          } else {
            Notiflix.Notify.failure(err.error.statusDesc);
            dis.getotp = true;
          }
        }
      })
  }
  setpwd() {
    let dis = this;
    let reqBody = {
      "userName": dis.setPwd_form.get('userName')?.value,
      "mobileNumber": dis.setPwd_form.get('mob')?.value,
    };
    dis.appService.post_method(allApi.url.setpass, reqBody)
      .pipe()
      .subscribe({
        next(res: any) {

        },
        error(err: any) {

        }
      });

  }




  getOtpValueotp = (e: any) => {
    this.modal_input_otp = e;
  }

  resendUserOtp() {
    Notiflix.Loading.hourglass();
    let logininfo: any = JSON.parse(sessionStorage.getItem('loginInfo'))
    let resendOtpBody = {
      "user_name": this.login_form.get('userName')?.value,
      "admin_name": logininfo.adminName,
      "feature_name": "OTP",
      "operation_performed": "OTP",
      "MobileNumber": logininfo.mobileNumber,
      "params": this.paramsKey,
      "Email": "",
      "messageData":
        { "otp": "otp" }
    }
    this.appService.post_method(allApi.url.resendOtp, resendOtpBody)
      .pipe(finalize(() => { Notiflix.Loading.remove(); }))
      .subscribe((res: any) => {
        Notiflix.Notify.success(res.message);
        this.Timer(30);
      })
  }

  Timer(second: any) {
    this.resentOtp = true;
    let seconds: number = second;
    let textSec: any = '0';
    let statSec: number = 0;
    statSec = seconds;
    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--; else statSec = 59;
      if (statSec < 10) { textSec = '0' + statSec; }
      else textSec = statSec;
      this.display = `${textSec}`;
      if (seconds == 0) {

        clearInterval(this.timerInterval);
        this.resentOtp = false;
      }
    }, 1000);
  }

  ValidateUserOtp() {
    Notiflix.Loading.arrows();
    let logininfo: any = JSON.parse(sessionStorage.getItem('loginInfo'))
    let userCheck = {
      "mobileNo": logininfo.mobileNumber,
      "params": this.paramsKey,
      "otp": this.modal_input_otp
    }
    this.appService.post_method(allApi.url.ValidateOtp, userCheck).subscribe((res: any) => {

      Notiflix.Loading.remove();
      Notiflix.Notify.success(res.message);
      this.router.navigate(['distributor/dashboard']);


























    }, (err: any) => {

      if (err.error.statusCode == -2) {
        this.otpModal.hide();
      }
      Notiflix.Notify.failure(err.error.message);
      Notiflix.Loading.remove();
    });

  }


  async ValidateUserSendOtp() {
    Notiflix.Loading.arrows();
    let logininfo: any = JSON.parse(sessionStorage.getItem('loginInfo'))
    let userOtpBody = {
      "user_name": this.login_form.get('userName')?.value,
      "admin_name": logininfo.adminName,
      "feature_name": "OTP",
      "operation_performed": "OTP",
      "MobileNumber": logininfo.mobileNumber,
      "params": this.paramsKey,
      "Email": "",
      "messageData":
        { "otp": "otp" }
    }
    this.appService.post_method(allApi.url.requestOtp, userOtpBody)
      .pipe(finalize(() => { Notiflix.Loading.remove(); }))
      .subscribe(async (res: any) => {
        Notiflix.Notify.success(await res.message);
        this.Timer(30);

        this.otpModal.show();
      })
  }

  generateParamsKey() {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charLen = chars.length;

    let result = ' ';
    for (let i = 0; i < 10; i++) {
      result += chars.charAt(Math.floor(Math.random() * charLen));
    }
    return result;
  }

  closeOtpmodal() {
    this.otpModal.hide();
    this.resentOtp = false;
    clearInterval(this.timerInterval);
    this.otp_form.reset();
  }


  dashBoard = () => {

    let dis = this;
    this.appService.get_method(allApi.url.dashboard).pipe().subscribe({
      async next(res: any) {
        dis.sign = false;

        dis.username = await res.userInfo.userName;
        dis.brand_name = res.userInfo.userBrand;

        localStorage.setItem('user_type', res.userInfo.userType);
        localStorage.setItem('user_name', res.userInfo.userName);
        localStorage.setItem('brand_name', res.userInfo.userBrand);
        sessionStorage.setItem('dashboardData', JSON.stringify(res.userInfo));
        let dashboardData = JSON.parse(sessionStorage.getItem('dashboardData'));
        sessionStorage.setItem('kycActiveStatus', dashboardData.userProfile.kycActiveStatus);
        let brandName = res.userInfo.userProfile.parentBrand ? res.userInfo.userProfile.parentBrand : 'N/A';
        sessionStorage.setItem('brandName', brandName);
        dis.isPasswordResetRequired = sessionStorage.getItem('isPasswordResetRequired');


        let kycStatus = dashboardData.userProfile.kycActiveStatus
        if (dis.is2fa && dis.isPasswordResetRequired == 'false' && kycStatus == '10') {
          dis.paramsKey = dis.generateParamsKey();
          dis.ValidateUserSendOtp();
          Notiflix.Loading.remove();
        } else {
          dis.router.navigate(['distributor/dashboard']);
        }
      }, error(err: any) {
        Notiflix.Loading.remove();
        Notiflix.Report.failure('Opps !!!', 'Something Went Wrong. Kindly Try Login Again', 'Close');

      }
    })
  }


}
