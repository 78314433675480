import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as Notiflix from 'notiflix';
import { assetUrl } from 'src/single-spa/asset-url';
import { AppService } from '../app.service';
import { allApi } from '../appApi';

@Component({
  selector: 'app-verifyuser',
  templateUrl: './verifyuser.component.html',
  styleUrls: ['./verifyuser.component.scss'],
})
export class VerifyuserComponent implements OnInit {
  fieldTypePass: boolean = false;
  after_verify: boolean = false;
  verify_form: FormGroup;
  verifyOTP_form: FormGroup;
  logoIcon1: any = assetUrl('images/kotak_logo1.png');
  @ViewChild('otpverifyModal', { static: false }) private verify_Modal: any;
  logoImg: any = {
    logo1: assetUrl('images/kotak_logo.png'),
    logo2: assetUrl('images/kotak_logo1.png'),
  };
  stateArr: any = [
    {
      name: 'Odisha',
      code: 'OD',
    },
    {
      name: 'Bihar',
      code: 'BH',
    },
    {
      name: 'Madhya Pradesh',
      code: 'MP',
    },
    {
      name: 'Andhra Pradesh',
      code: 'AP',
    },
    {
      name: 'West Bengal',
      code: 'WB',
    },
  ];
  adminuser_Name: any;
  parentuser_Name: any;
  cbc_name: any;
  verify_otpbtn: boolean = true;
  email_otp: any;
  mobile_otp: any;
  otp_reference: any;
  mobileotp_ref: any;
  emailotp_ref: any;
  firstName: any;

  resend_otp_email: boolean = false;
  resend_otp_mobile: boolean = false;
  timerInterval_mobile: any;
  timerInterval_email: any;
  display_mobile: string;
  display_email: string;
  errorFlag: any = {
    fname: true,
    mname: true,
    lname: true,
  };
  constructor(
    private _appService: AppService,
    private http: HttpClient,
    private router: Router
  ) {
    this.verify_form = new FormGroup({
      fname: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z ]*$'),
      ]),
      middlename: new FormControl('', [
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z ]*$'),
      ]),
      lname: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z ]*$'),
      ]),
      email: new FormControl('', [Validators.required]),
      mob: new FormControl('', [Validators.required]),
      state_code: new FormControl('Choose State', [Validators.required]),
    });
    this.verifyOTP_form = new FormGroup({
      email_otp: new FormControl('', [Validators.required]),
      mble_otp: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.getVerifyData();
  }

  onlyAlphabet(event: any) {
    return (
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 && event.charCode <= 122)
    );
  }
  hideHint = (e: any) => {
    switch (e) {
      case 'fname':
        this.errorFlag.fname = false;
        break;
      case 'mname':
        this.errorFlag.mname = false;
        break;
      case 'lname':
        this.errorFlag.lname = false;
        break;
    }
  };

  mobileTimer(second: any) {
    this.resend_otp_mobile = true;
    let seconds: number = second;
    let textSec: any = '0';
    let statSec: number = seconds;
    this.timerInterval_mobile = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 119;
      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;
      this.display_mobile = `${textSec}`;
      if (seconds == 0) {
        clearInterval(this.timerInterval_mobile);
        this.resend_otp_mobile = false;
      }
    }, 1000);
  }
  emailTimer(second: any) {
    this.resend_otp_email = true;
    let seconds: number = second;
    let textSec: any = '0';
    let statSec: number = seconds;
    this.timerInterval_email = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 119;
      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;
      this.display_email = `${textSec}`;
      if (seconds == 0) {
        clearInterval(this.timerInterval_email);
        this.resend_otp_email = false;
      }
    }, 1000);
  }

  get_state_code = () => {};
  getVerifyData = () => {
    let urlData = JSON.parse(sessionStorage.getItem('urlData'));

    let dis = this;
    this._appService.$verifyData.subscribe({
      next(res: any) {
        dis.verify_form.patchValue({
          mob: res.mobile_no ? res.mobile_no : urlData.url_mob,
          email: res.user_email ? res.user_email : urlData.url_email,
          state_code: res.state_code ? res.state_code : urlData.url_state,
        });
        dis.adminuser_Name = res.admin_userName
          ? res.admin_userName
          : urlData.url_username;
        dis.parentuser_Name = res.parent_userName
          ? res.parent_userName
          : urlData.url_parent_username;
        dis.cbc_name = res.cbc_name ? res.cbc_name : urlData.cbc_name;
      },
      error(err: any) {},
    });
  };
  Verify_user = () => {
    Notiflix.Loading.arrows('Loading...');
    this.emailFocusOutEvent();
  };
  emailFocusOutEvent() {
    Notiflix.Loading.arrows('Loading...');
    const bodyData = {
      email: this.verify_form.get('email').value,
    };
    let url = allApi.url.dedupChk;
    this.http.post(url, bodyData).subscribe({
      next: (res: any) => {
        if (res.status === 1) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success('Email verification Success');
          this.mobileFocusOutEvent();
        } else if (res.status === 0) {
          Notiflix.Loading.remove();
          Notiflix.Report.failure(
            'Sorry !!!',
            'Email already exist,Please Login with your existing User ID & Password',
            'close'
          );
        }
      },
      error: (err: any) => {
        Notiflix.Loading.remove();

        Notiflix.Notify.failure('Email verify fail');
      },
    });
  }
  mobileFocusOutEvent() {
    let dis = this;
    Notiflix.Loading.arrows('Loading...');
    const bodyData = {
      mobile_number: this.verify_form.get('mob').value,
    };
    let url = allApi.url.dedupChk;
    this.http.post(url, bodyData).subscribe({
      next: (res: any) => {
        if (res.status === 1) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success('Mobile verification Success');

          dis.send_otp_email('send');
        } else if (res.status == 0) {
          Notiflix.Loading.remove();
          Notiflix.Report.failure(
            'Sorry !!!',
            'Mobile number already exist,Please Login with your existing User ID & Password',
            'close'
          );
        }
      },
      error: (err: any) => {
        Notiflix.Loading.remove();

        Notiflix.Notify.failure('Mobile verify fail');
      },
    });
  }
  send_otp_mobile = (e: any) => {
    let dis = this;
    let mobileID = this.verify_form.get('mob').value;
    const mobilebody = {
      mobile_no: mobileID,
    };
    let url = allApi.url.sendMobileOtp;
    this.http.post(url, mobilebody).subscribe((res: any) => {
      if (e == 'send') {
        dis.resend_otp_mobile = true;
        dis.resend_otp_email = true;
        dis.mobileTimer(120);
        dis.emailTimer(120);
      } else if (e == 'resend') {
        dis.resend_otp_mobile = true;
        dis.mobileTimer(120);
      }
      if (res.status == 0) {
        dis.mobileotp_ref = res.ref;
        Notiflix.Notify.success('OTP Sent Successfully !!');
        dis.verify_Modal.show();
      } else {
        Notiflix.Report.failure('Failed', res.message, 'Close');
      }
    }),
      (err: any) => {
        Notiflix.Report.failure('Failed', 'Enter Otp Again', 'Close');
      };
  };
  send_otp_email = (e: any) => {
    let dis = this;
    let emailId = this.verify_form.get('email').value;
    const emailbody = {
      email_id: emailId,
    };
    let url = allApi.url.sendEmailOtp;
    this.http.post(url, emailbody).subscribe((res: any) => {
      if (res.status == 0) {
        dis.emailotp_ref = res.ref;
        if (e == 'send') {
          dis.send_otp_mobile('send');
        }
        if (e == 'resend') {
          dis.emailTimer(120);
        }
      } else {
        Notiflix.Report.failure('Failed', res.message, 'Close');
      }
    }),
      (err: any) => {
        Notiflix.Report.failure('Failed', 'Enter Otp Again', 'Close');
      };
  };
  Verify_logincheck = () => {
    let dis = this;
    Notiflix.Loading.arrows('Loading...');
    dis.firstName = this.verify_form.get('fname').value;
    let featureData = {
      firstName: this.verify_form.get('fname').value,
      lastName: this.verify_form.get('lname').value,
      email: this.verify_form.get('email').value,
      roleId: 5,
      mobileNumber: this.verify_form.get('mob').value,
      userType: 'TRANSACTIONAL',
      adminUserName: this.adminuser_Name ? this.adminuser_Name : 'kotak_admin',
      parentUserName: this.cbc_name,
      state: this.verify_form.get('state_code').value,
      isUsing2FA: true,
    };

    let url = allApi.url.registerUser;
    this.http.post(url, featureData).subscribe({
      next(res: any) {
        if (res) {
          dis.verify_otpbtn = true;
          Notiflix.Loading.remove();
          Notiflix.Report.success(
            'Success',
            'User Created Sucessfully',
            'Close'
          );

          dis.verify_Modal.hide();
          dis.after_verify = true;
        }
      },
      error(err: any) {
        dis.verify_otpbtn = true;
        dis.after_verify = false;

        Notiflix.Loading.remove();
        Notiflix.Report.failure('Failed', err.error.message, 'Close');
        dis.verify_Modal.hide();
      },
    });
  };
  getOtpValue = (e: any, mode: any) => {
    if (mode == 'email') {
      this.email_otp = e;
    } else {
      this.mobile_otp = e;
    }
  };
  verifyEmailOtp = () => {
    let dis = this;
    Notiflix.Loading.arrows();
    const emailbody = {
      otp: this.email_otp,
      ref: this.emailotp_ref.toString(),
    };
    let url = allApi.url.verifyEmailOtp;
    this.http
      .post(url, emailbody)
      .pipe()
      .subscribe({
        next(res: any) {
          if (res.status == 0) {
            dis.verifyMobileOtp();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Report.failure('Failed', res.statusDesc, 'Close');
          }
        },
        error(err: any) {
          Notiflix.Loading.remove();
          Notiflix.Report.failure('Failed', 'Enter Otp Again', 'Close');
        },
      });
  };
  verifyMobileOtp = () => {
    let dis = this;
    Notiflix.Loading.arrows();
    const mobilebody = {
      otp: this.mobile_otp,
      ref: this.mobileotp_ref.toString(),
    };
    let url = allApi.url.verifyMobileOtp;
    this.http
      .post(url, mobilebody)
      .pipe()
      .subscribe({
        next(res: any) {
          if (res.status == 0) {
            Notiflix.Loading.remove();
            dis.verify_otpbtn = false;
            dis.verifyOTP_form.reset();
            Notiflix.Notify.success('OTP Verified Successfully !!');
          } else {
            Notiflix.Loading.remove();
            dis.verify_otpbtn = true;
            Notiflix.Report.failure('Failed', res.statusDesc, 'Close');
          }
        },
        error(err: any) {
          dis.verify_otpbtn = true;
          Notiflix.Loading.remove();
          Notiflix.Report.failure('Failed', 'Enter Otp Again', 'Close');
        },
      });
  };
  verifyOTP() {
    if (this.email_otp && this.mobile_otp) {
      if (this.email_otp.length == 6 && this.mobile_otp.length == 6) {
        this.verifyEmailOtp();
      } else if (this.email_otp.length != 6) {
        Notiflix.Report.warning(
          'Invalid OTP',
          'Email OTP Must be 6 Digits',
          'Close'
        );
      } else {
        Notiflix.Report.warning(
          'Invalid OTP',
          'Mobile OTP Must be 6 Digits',
          'Close'
        );
      }
    } else {
      Notiflix.Report.warning('Opps!!!', 'First Enter Both The OTP', 'Close');
    }
  }
  maskValue = (e: any) => {};
  create_userAgent() {
    this.Verify_logincheck();
  }
  login_app() {
    this.router.navigate(['login']);
  }
}
