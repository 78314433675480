import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  firsttitem: string;
  verification_data: any = {
    mobile_no: '',
    user_email: '',
    state_code: '',
    parent_userName: '',
    admin_userName: '',
    cbc_name: '',
  };
  $verifyData = new BehaviorSubject('');

  constructor(
    private _http: HttpClient,
    private query: ActivatedRoute,
    private router: Router
  ) {}
  authenticateToken() {
    this.query.queryParams.subscribe(
      (res: any) => {
        if (res.state) {
          this.router.navigate(['/verify']);
          let urlData: any = {
            url_mob: res.mobile,
            url_email: res.email,
            url_state: res.state,
            url_cbc_name: res.cbc_name,
            url_username: res.adminUserName,
            url_parent_username: res.parentUserName,
          };
          sessionStorage.setItem('urlData', JSON.stringify(urlData));
          this.verification_data.mobile_no = res.mobile ? res.mobile : '';
          this.verification_data.user_email = res.email ? res.email : '';
          this.verification_data.admin_userName = res.adminUserName
            ? res.adminUserName
            : '';
          this.verification_data.parent_userName = res.parentUserName
            ? res.parentUserName
            : '';
          this.verification_data.state_code = res.state ? res.state : '';
          this.verification_data.cbc_name = res.cbc_name ? res.cbc_name : '';
          this.$verifyData.next(this.verification_data);
        }
      },
      (err: any) => {}
    );
  }

  get_method = (url: any) => {
    return this._http.get(url);
  };
  post_method = (url: any, reqBody: any) => {
    return this._http.post(url, reqBody);
  };
}
