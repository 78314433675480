//////////// staging///////////////////
 const StagingEnvironment = {
  production: false,
  LoginUrl: 'https://oauth2-auth-server-kotak-bank-uat-twdwtabx5q-el.a.run.app',
  BioAuthUrl: 'https://apidev.iserveu.online/kotak',
  OtpService: 'https://user-registration-kotak-bank-uat-twdwtabx5q-el.a.run.app',
  userRegistrationBaseUrl: 'https://user-registration-kotak-bank-uat-twdwtabx5q-el.a.run.app',
  otpServiceUrl: 'https://otp-service-twdwtabx5q-uc.a.run.app',
  dedupChk: 'https://elasticuser-staging-kotak-twdwtabx5q-uc.a.run.app'
};


//////////// uat ///////////////
const UatEnvironment = {
  production: false,
  LoginUrl:
    'https://isu-kotak-user-authorization-java-stage-uat-twdwtabx5q-el.a.run.app',
  BioAuthUrl:
    'https://isu-kotak-user-authorization-java-stage-uat-twdwtabx5q-el.a.run.app',
  OtpService:
    'https://isu-kotak-user-management-java-stage-uat-twdwtabx5q-el.a.run.app',
  userRegistrationBaseUrl:
    'https://isu-kotak-user-management-java-stage-uat-twdwtabx5q-el.a.run.app',
  otpServiceUrl:
    'https://isu-kotak-user-management-java-stage-uat-twdwtabx5q-el.a.run.app',
  dedupChk:
    'https://eelasticuser-staging-kotak-stage-uat-twdwtabx5q-uc.a.run.app',
};
////////////////// PROD ////////////
 const ProdEnvironment = {
  production: true,
  LoginUrl: 'https://oauth2-auth-server-kotak-bank-prod.txninfra.com',
  BioAuthUrl: 'https://apiprod.txninfra.com/kotak',
  OtpService: 'https://apiprod.txninfra.com/kotak',
  userRegistrationBaseUrl:
    'https://user-registration-kotak-bank-prod.txninfra.com',
  otpServiceUrl: 'https://otp-service-kotak-prod-qogolrzipa-el.a.run.app',
  dedupChk: 'https://kotak-elastic-prod.txninfra.com',
};



export const environment = UatEnvironment;
