<div class="container main_block dfc">
    <div class="card outer_card dfc">
        <div class="card card_width m-0 p-0">
            <div class="card_width m-0 p-0 col-sm-12">
                <div class="col-sm-12">
                    <div class="row col-sm-12 m-0 p-0">
                        <div class="p-0 mt-2 dfc_sml">
                            <img [src]="logoImg.logo1" alt="logo" class="logo">
                        </div>
                    </div>
                    <div class="row col-sm-12 m-0 p-0" *ngIf="!after_verify">
                        <div class="textData mt-5 dfc">
                            <p class="welcome">Kindly Verify User</p>
                            <p>Please enter your details.</p>
                        </div>
                    </div>
                    <div class="row col-sm-12 m-0 p-0" *ngIf="after_verify">
                        <div class="textData mt-5 dfc">
                            <p class="welcome">Hi,{{firstName}} User Created Successfully !!</p>
                            <p>Your credential has been sent to your registered Email Id</p>
                            <button class="btn login_btn my-5" (click)="login_app()">
                                <span>Click to Login</span>
                            </button>
                        </div>
                    </div>
                    <form [formGroup]="verify_form" *ngIf="!after_verify">
                        <div class="col-sm-12 row p-3 m-0">
                            <div class="mt-1 col-sm-4 p-2 m-0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Enter First Name</mat-label>
                                    <input matInput autocomplete="off"
                                        (keypress)="onlyAlphabet($event)" (focusin)="hideHint('fname')" maxlength="50" formControlName="fname">
                                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                                    <mat-hint *ngIf="errorFlag.fname">*Only Alphabets allow</mat-hint>
                                    <mat-hint class="text-danger" *ngIf="verify_form.get('fname').hasError('minlength') && verify_form.get('fname').touched">Minimum 2 Charecters</mat-hint>
                                    <mat-hint class="text-danger" *ngIf="verify_form.get('fname').hasError('required') && verify_form.get('fname').touched">Its Required</mat-hint>
                                </mat-form-field>
                                <!-- <label for="">First Name<span class="text-danger">*</span>
                                </label> 
                                <input type="text" formControlName="fname" class="form-control w-100 mt-1"> -->
                            </div>
                            <div class="mt-1 col-sm-4 p-2 m-0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Enter Middle Name</mat-label>
                                    <input matInput autocomplete="off" (focusin)="hideHint('mname')"
                                        (keypress)="onlyAlphabet($event)" maxlength="50" formControlName="middlename">
                                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                                    <mat-hint *ngIf="errorFlag.mname">*Only Alphabets allow</mat-hint>
                                    <mat-hint class="text-danger" *ngIf="verify_form.get('middlename').hasError('minlength') && verify_form.get('middlename').touched">Minimum 2 Charecters</mat-hint>
                                </mat-form-field>
                                <!-- <label for="">Middle Name</label>
                                <input type="text" formControlName="middlename" class="form-control w-100 mt-1"> -->
                            </div>
                            <div class="mt-1 col-sm-4 p-2 m-0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Enter Last Name</mat-label>
                                    <input matInput autocomplete="off" (focusin)="hideHint('lname')"
                                        (keypress)="onlyAlphabet($event)" maxlength="50" formControlName="lname">
                                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                                    <mat-hint *ngIf="errorFlag.lname">*Only Alphabets allow</mat-hint>
                                    <mat-hint class="text-danger" *ngIf="verify_form.get('lname').hasError('minlength') && verify_form.get('lname').touched">Minimum 2 Charecters</mat-hint>
                                    <mat-hint class="text-danger" *ngIf="verify_form.get('lname').hasError('required') && verify_form.get('lname').touched">Its Required</mat-hint>
                                </mat-form-field>
                                <!-- <label for="">Last Name<span class="text-danger">*</span>
                                </label>
                                <input type="text" formControlName="lname" class="form-control w-100 mt-1"> -->
                            </div>
                            <div class="mt-1 col-sm-4 p-2 m-0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Enter State Name</mat-label>
                                    <input matInput autocomplete="off" formControlName="state_code" readonly>
                                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                                    <mat-hint>*Only Alphabets allow</mat-hint>
                                </mat-form-field>
                                <!-- <label for="">State<span class="text-danger">*</span>
                                </label>
                                <input type="text" formControlName="state_code" class="form-control w-100 mt-1"
                                    readonly> -->
                            </div>
                            <div class="mt-1 col-sm-4 p-2 m-0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Enter Email</mat-label>
                                    <input matInput autocomplete="off" formControlName="email" readonly>
                                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                                    <mat-hint>*Only Alphabets allow</mat-hint>
                                </mat-form-field>
                                <!-- <label for="">Email<span class="text-danger">*</span>
                                </label>
                                <input type="text" formControlName="email" class="form-control w-100 mt-1" readonly> -->
                            </div>
                            <div class="mt-1 col-sm-4 p-2 m-0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Enter Mobile Number</mat-label>
                                    <input matInput autocomplete="off" formControlName="mob" readonly>
                                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                                    <mat-hint>*Only Alphabets allow</mat-hint>
                                </mat-form-field>
                                <!-- <label for="">Mobile Number<span class="text-danger">*</span>
                                </label>
                                <input type="text" formControlName="mob" class="form-control w-100 mt-1" readonly> -->
                            </div>
                        </div>
                    </form>
                    <div class="col-sm-12" *ngIf="!after_verify">
                        <div class="verify_btn_div">
                            <button class="btn login_btn" (click)="Verify_user()"
                                [disabled]="verify_form.get('fname').invalid || verify_form.get('lname').invalid">
                                <span>Verify & Send OTP</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<app-ui-modal #otpverifyModal [containerClick]="false" class="custom_modal_height">
    <div class="app-modal-header dfc">
        <h3 style="font-size: 15px; color: #fff;">Verify Email & Mobile via OTP</h3>
    </div>
    <div class="app-modal-body">
        <form [formGroup]="verifyOTP_form">
            <div class="col-sm-12 row p-3 m-0" *ngIf="verify_otpbtn">
                <div class="mt-1 col-sm-12 p-2 m-0">
                    <label for="">Enter OTP sent to Email<span class="text-danger">*</span>
                    </label>
                    <div class="otp_secw">
                        <ng-otp-input (onInputChange)="getOtpValue($event,'email')" [control]="email_otp"
                            [config]="{ length: 6, allowNumbersOnly: true }" maxlength="6"></ng-otp-input>
                        <div class="resend">
                            <p *ngIf="resend_otp_email">{{display_email}} sec</p>
                            <button class="btn resend_otp ml-1" [disabled]="resend_otp_email" (click)="send_otp_email('resend')">Resend</button>
                        </div>
                    </div>
                </div>
                <div class="mt-1 col-sm-12 p-2 m-0">
                    <label for="">Enter OTP sent to Mobile<span class="text-danger">*</span>
                    </label>
                    <div class="opt_secw">
                        <ng-otp-input data-mask='******' [control]="mble_otp"
                            (onInputChange)="getOtpValue($event,'mobile');maskValue($event)"
                            [config]="{ length: 6, allowNumbersOnly: true }" maxlength="6"></ng-otp-input>
                        <div class="resend">
                            <p *ngIf="resend_otp_mobile">{{display_mobile}} sec</p>
                            <button class="btn resend_otp ml-1" [disabled]="resend_otp_mobile" (click)="send_otp_mobile('resend')">Resend</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 row p-3 m-0" *ngIf="!verify_otpbtn">
                <div class="textData mt-5 dfc">
                    <p style="font-size: 1.25rem; color: #FF0000;">OTP verification Successfull !!</p>
                </div>
            </div>
            <div class="col-sm-12 row p-3 m-0">

                <div class="mt-1 col-sm-6 p-2 m-0">
                    <button type="button" class="btn succ_btn mx-2 global_btn" (click)="verifyOTP()"
                        *ngIf="verify_otpbtn">Verify OTP</button>
                    <button type="button" class="btn can_btn mx-2 global_btn" *ngIf="!verify_otpbtn"
                        (click)="create_userAgent()">Create User</button>
                </div>
            </div>
        </form>
    </div>
</app-ui-modal>